import React from 'react'
import {graphql, Link} from 'gatsby'
import LayoutSecondary from "../components/common/layout-secondary";
import SEO from "../components/common/seo";

const HubspotBlogTemplate = (props) => {
    const posts = props.pageContext.posts;

    console.log(props);

    return (
        <LayoutSecondary pageContext={props.pageContext} cookies={props.data.cookies}>
            <SEO title={"Blog Post - Tecalis"}
                 description={"Blog Post"}/>

            <section className="bg-grey pt-8 pt-md-11">
                <div className="container">
                    {posts.map((element, i) => {
                        return <Link key={i} to={`/es/blog/${element.id}`}>{element.htmlTitle}</Link>
                    })}
                </div>
            </section>

        </LayoutSecondary>
    )
}

export default HubspotBlogTemplate

export const hubspotBlogPageQuery = graphql`
  query HubspotBlogTemplate($lang: String) {
    cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
  }
`
